




















































import Vue from 'vue';
import {Backend} from "api-frontend";
import {Roles, User, UserWithoutPassword} from "mp-common/src/types/entities/user";
import {users} from "mp-common/src/types/api/usersApi";

export default Vue.extend({
  name: 'ModifyFamilies',
  props: {
    user: {} as () => User,
    backend: {
      type: Backend,
      required: true
    }
  },
  data() {
    return {
      users: [] as UserWithoutPassword[],
      userToAddRoleTo: undefined as UserWithoutPassword | undefined,
      requesting: false,
      showAddRoleDialog: false,
      roleToAdd: "",
    }
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      const result = await this.backend.query(users.getAll, undefined) ?? [];
      this.users = result.sort((item, secondItem) => item.lastName.localeCompare(secondItem.lastName))
    },
    async addRole() {
      if (!this.userToAddRoleTo || !this.roleToAdd) {
        throw new Error("No user to add role to or role to add to the user");
      }
      const newRole = Roles.fromString(this.roleToAdd);
      if (!this.userToAddRoleTo.roles.includes(newRole)) {
        this.userToAddRoleTo.roles.push(newRole);
        const result = await this.backend.query(users.update, this.userToAddRoleTo);
        if (!result?.success) {
          await this.fetchUsers();
        }
      }
      this.showAddRoleDialog = false;
    },
    async deleteRole(user: UserWithoutPassword, role: Roles) {
      user.roles = user.roles.filter(item => item != role);
      const result = await this.backend.query(users.update, user);
      if (!result?.success) {
        await this.fetchUsers();
      }
    },
    openAddRoleDialog(user: UserWithoutPassword) {
      this.userToAddRoleTo = user;
      this.showAddRoleDialog = true;
    },
    getNonPresentRoles() {
      return Roles.getValues()
          .filter((item) => !this.userToAddRoleTo?.roles.includes(item))
          .map((item) => item.toString())
    },
    sortAndFormatRoles(roles: Roles[]) {
      return roles.map((item) => item.toString()).sort((first, next) => first.localeCompare(next))
    }
  }
})
