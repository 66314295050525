import * as t from "io-ts";
import {Roles, UserWithoutPassword} from "../entities/user";
import {validateApi} from "api-common";

export const users = validateApi({
    getAll: {
        path: "/users/getAll",
        params: t.undefined,
        queryParams: t.undefined,
        returns: t.array(UserWithoutPassword),
        roles: [Roles.ACTIVE, Roles.ASSIGNMENT_COORDINATOR]
    },
    update: {
        path: "/users/update",
        params: UserWithoutPassword,
        queryParams: t.undefined,
        returns: t.type({success: t.boolean}),
        roles: [Roles.ACTIVE, Roles.ADMIN]
    }
})